import * as React from "react";
import { DivLabel, LargeFocusDiv } from "./styles";
import { ProjectInfo, Deployment } from "../../types";

const GMapsAPIKey = "AIzaSyAXJu5589pgaCJ65w4Y2IwT-M5Nec-_cRQ";

export const ProjectMap = (props: {
  project: ProjectInfo;
  center: { lat: number; lng: number };
}) => {
  return (
    <LargeFocusDiv>
      <Map deployments={props.project.deployments} center={props.center} />
      <DivLabel>Map</DivLabel>
    </LargeFocusDiv>
  );
};

const Map = (props): any => {
  const anchorURL = new URL("https://www.google.com/maps/@");
  anchorURL.searchParams.set("api", "1");
  anchorURL.searchParams.set("map_action", "map");
  anchorURL.searchParams.set(
    "center",
    positionString(props.center.lat, props.center.lng),
  );
  anchorURL.searchParams.set("zoom", "13");
  anchorURL.searchParams.set("basemap", "satellite");

  const imgURL = new URL("https://maps.googleapis.com/maps/api/staticmap");
  imgURL.searchParams.set(
    "center",
    positionString(props.center.lat, props.center.lng),
  );
  imgURL.searchParams.set(
    "markers",
    markersString({ color: "white" }, props.deployments),
  );
  imgURL.searchParams.set("zoom", "13");
  imgURL.searchParams.set("maptype", "satellite");
  imgURL.searchParams.set("key", GMapsAPIKey);
  imgURL.searchParams.set("size", sizeString(498, 358));
  imgURL.searchParams.set("scale", "2");

  return (
    <a href={anchorURL} target={"_blank"}>
      <img src={imgURL} style={{ width: "100%", height: "100%" }} />
    </a>
  );
};

const markersString = (
  style: Object<string, string>,
  deployments: [Deployment],
): string => {
  const styleStrings = [];
  for (let key in style) {
    styleStrings.push(key + ":" + style[key]);
  }
  const markerStrings = [];
  for (let dep of deployments) {
    markerStrings.push(positionString(dep.latitude, dep.longitude));
  }
  return styleStrings.join("|") + "|" + markerStrings.join("|");
};

const positionString = (lat: number, lng: number): string => {
  return lat + "," + lng;
};

const sizeString = (width: number, height: number): string => {
  return width + "x" + height;
};
