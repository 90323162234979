import * as React from "react";
import { FullLogo, StyledNavRow } from "../styles";
import { LoggedIn } from "./LogIn";

export const NavHeader = () => {
  return (
    <StyledNavRow>
      <div className={"navGroup"}></div>
      <FullLogo onClick={() => window.open("https://streamocean.io")} />

      <div className={"navGroup"}>
        <LoggedIn />
      </div>
    </StyledNavRow>
  );
};
