import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 17" {...props}>
    <path d="M29 1L15 16L1 0.999992" fill="#FFA9A0" />
    <path
      d="M29 1L15 16L1 0.999992L29 1Z"
      stroke="#FFA9A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const EyesIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M182.083 0H55.5835H55.0835V0.00220545C24.6619 0.270672 0.0834961 25.015 0.0834961 55.5C0.0834961 86.1518 24.9317 111 55.5835 111C82.9963 111 105.767 91.1257 110.274 65H127.886C132.352 91.1257 154.918 111 182.083 111C212.459 111 237.083 86.1518 237.083 55.5C237.083 24.8482 212.459 0 182.083 0ZM221.083 55C221.083 74.8822 204.966 91 185.083 91C165.201 91 149.083 74.8822 149.083 55C149.083 35.1177 165.201 19 185.083 19C204.966 19 221.083 35.1177 221.083 55ZM54.0835 91C73.9657 91 90.0835 74.8822 90.0835 55C90.0835 35.1177 73.9657 19 54.0835 19C34.2012 19 18.0835 35.1177 18.0835 55C18.0835 74.8822 34.2012 91 54.0835 91Z"
      fill="#FF6F61"
      fill-opacity="0.3"
    />
  </SvgIcon>
);

export const OverlapIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <circle
      cx="95.7731"
      cy="127.818"
      r="69.8666"
      transform="rotate(-30 95.7731 127.818)"
      fill="#FF6F61"
      fill-opacity="0.2"
    />
    <circle
      cx="130.561"
      cy="95.4395"
      r="69.8666"
      transform="rotate(-30 130.561 95.4395)"
      fill="#FF6F61"
      fill-opacity="0.2"
    />
  </SvgIcon>
);

export const ClockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M160.917 80C160.917 124.183 125.099 160 80.9165 160C36.7337 160 0.916504 124.183 0.916504 80C0.916504 35.8172 36.7337 0 80.9165 0C125.099 0 160.917 35.8172 160.917 80ZM75.9165 76V11H85.9165V76L144.917 76V86L75.9165 86V80V76Z"
      fill="#FF6F61"
      fill-opacity="0.3"
    />
  </SvgIcon>
);

export const MapMarkerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M45 22.5301C45 27.8986 43.1227 32.8363 39.9883 36.7054L22.5021 60L5.01172 36.7054C1.87729 32.8363 0 27.8986 0 22.5301C0 10.0866 10.0741 0 22.5021 0C34.9302 0 45 10.0866 45 22.5301Z"
      fill="white"
    />
  </SvgIcon>
);
