import * as React from "react";
import { ProjectInfo } from "../../types";
import { DivLabel, DivMessage, YoutubeDiv } from "./styles";
import { Theme } from "../../styles";
import {
  ContentType,
  FetchLatestMedia,
  Media,
  MediaType,
} from "../../api/general";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import YouTube from "react-youtube";
import getYouTubeID from "get-video-id";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function findMostRecentYoutubeMedia(media: Media[]): Media | null {
  for (let i = media.length - 1; i >= 0; i--) {
    if (media[i].url.includes("youtube")) {
      return media[i];
    }
  }
  return null;
}

export const FeaturedHighlight = (props: {
  project: ProjectInfo;
  selectedDeploymentId: number | null;
}) => {
  // todo: default datestart should be 24 hours ago, dateend now
  const [contentType, setContentType] = useState<ContentType | undefined>(5);
  const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
  const [media, setMedia] = useState<Media[]>([]);
  const [fetching, setFetching] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (props.selectedDeploymentId == null) {
      return;
    }
    setFetching(true);
    try {
      const now = DateTime.now();
      FetchLatestMedia(user, {
        project_id: props.project.id,
        deployment_id: props.selectedDeploymentId,
        count: 5,
        content_type: 5,
      }).then((m) => {
        if (m) {
          setMedia(m);
        } else {
          setMedia([]);
        }
      });
    } catch (err) {
      alert(err);
    }
    setFetching(false);
  }, [contentType, mediaType, props.selectedDeploymentId, user]);

  let videoID = null;
  const mostRecent = findMostRecentYoutubeMedia(media);
  if (mostRecent) {
    videoID = getYouTubeID(mostRecent.url);
  }

  let content;
  if (videoID) {
    content = <YouTube videoId={videoID ? videoID.id : ""} />;
  } else {
    content = (
      <>
        <DivLabel>Featured Highlight</DivLabel>
        <DivMessage>No recent highlight video available.</DivMessage>
      </>
    );
  }
  return <YoutubeDiv>{content}</YoutubeDiv>;
};
