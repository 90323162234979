import * as React from "react";

export const GlobalPosition = (props: {
  latitude: number;
  logitude: number;
}) => {
  return (
    <>
      {Math.abs(props?.latitude)}°{props?.latitude > 0 ? "N" : "S"}{" "}
      {Math.abs(props?.longitude)}°{props?.longitude > 0 ? "E" : "W"}
    </>
  );
};
