import * as React from "react";
import { GlobalPosition } from "./GlobalPosition";
import { DateRange } from "./DateRange";
import { Deployment } from "../../types";

export const DeploymentDetail = (props: { deployment: Deployment }) => {
  return (
    <>
      <span className={"head"}>Camera {props.deployment?.camera_id}</span>
      Deployed{" "}
      <DateRange
        startDate={props.deployment?.start_date}
        endDate={props.deployment?.end_date}
      />{" "}
      at{" "}
      <GlobalPosition
        latitude={props.deployment?.latitude}
        longitude={props.deployment?.longitude}
      />
    </>
  );
};
