import * as React from "react";
import { DateTime } from "luxon";

const DATE_FORMAT: number = "YYYY-MM-DD"; // 24-hour time

export const DateRange = (props: { startDate: string; endDate: string }) => {
  if (props.startDate == null) {
    if (props.endDate == null) {
      return <></>; // Add an explicit "undefined" case?
    } else {
      return (
        <>
          until {DateTime.fromISO(props.endDate, { setZone: true }).toISODate()}
        </>
      );
    }
  } else {
    if (props.endDate == null) {
      return (
        <>
          since{" "}
          {DateTime.fromISO(props.startDate, { setZone: true }).toISODate()}
        </>
      );
    } else {
      return (
        <>
          {DateTime.fromISO(props.startDate, { setZone: true }).toISODate()} -{" "}
          {DateTime.fromISO(props.endDate, { setZone: true }).toISODate()}
        </>
      );
    }
  }
};
