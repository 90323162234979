import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  db,
  logInWithEmailAndPassword,
  logInWithGoogle,
  logout,
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "../firebase";
import styled from "styled-components";
import { Theme } from "../styles";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Avatar } from "@mui/material";

// the view when the user is logged in
export const LogIn = () => {
  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <LoginDiv>
      <h1>log in</h1>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <label>
        <input
          type={"checkbox"}
          onChange={(t) => setAgreed(t.target.checked)}
        />
        by logging in I agree to the Terms of Use and Privacy Policy of Stream
        Ocean Ag.
      </label>
      <button
        disabled={!agreed}
        onClick={() => logInWithEmailAndPassword(email, password)}
      >
        Login
      </button>
      <button disabled={!agreed} className="google" onClick={logInWithGoogle}>
        Login with Google
      </button>

      <p>
        <Link to="/reset">Forgot Password</Link>
        <br />
        Don't have an account? <Link to="/register">Register</Link> now.
      </p>
    </LoginDiv>
  );
};

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/projects", { replace: true });
  }, [user, loading]);
  return (
    <LoginDiv>
      <h1>register</h1>

      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Full Name"
      />
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button onClick={register}>Register</button>
      <button className="google" onClick={logInWithGoogle}>
        Register with Google
      </button>
      <p>
        Already have an account? <Link to="/login">Login</Link> now.
      </p>
    </LoginDiv>
  );
};

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    // todo: figure this out
    if (user) navigate("/projects", { replace: true });
  }, [user, loading]);
  return (
    <LoginDiv>
      <h1>reset password</h1>

      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <button onClick={() => sendPasswordReset(email)}>
        Send password reset email
      </button>
      <div>
        Don't have an account? <Link to="/register">Register</Link> now.
      </div>
    </LoginDiv>
  );
};

const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 300px;
  justify-self: center;
  align-self: center;
  padding: 30px;

  button,
  input {
    margin: 5px 0;
    height: 30px;
  }
  button {
    border: none;
    background-color: ${Theme.colors.grey};
    font-family: ${Theme.fonts.subtitle};
  }

  button.google {
    background-color: #4285f4;
  }

  button:hover {
    background-color: ${Theme.colors.white};
  }
`;

// NOW the logged-in component that can be embedded in the nav or something
type SOUser = {
  name: string;
  uid: string;
  authProvider: string;
  email: string;
  projectID?: number;
};
export const LoggedIn = () => {
  const [user, loading, error] = useAuthState(auth);

  // const [soUser, setU] = useState<SOUser|null>(null);
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const u = doc.docs[0].data() as SOUser;
      // we want to redirect the view if the user has a specific project enabled
      // first of all, if no project is set, we log out and redirect to log in
      if (u.projectID == undefined) {
        alert(
          "Your account has not been configured, please contact an administrator. redirecting in 5 seconds.",
        );

        setTimeout(() => {
          logout();
          window.location.assign("/login");
        }, 5000);
      } else if (u.projectID === 0) {
        // if projectid is zero, it's a superadmin and we can see everything
        // redirect to the projects page.
        if (window.location.pathname == "/login") {
          window.location.assign("/projects");
        }
      } else {
        // otherwise we redirect to the project page if they're not there already
        if (window.location.pathname != `/project/${u.projectID}`) {
          window.location.assign(`/project/${u.projectID}`);
        }
      }
      // setU(u);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    // if not logged in, the view should be redirected
    if (!user) {
      // redirect to log in if not there already

      if (window.location.pathname != "/login") {
        return window.location.assign("/login");
      } else {
        return;
      }
    }
    fetchUserName();
  }, [user, loading]);

  if (!user) {
    return (
      <>
        <button onClick={() => window.location.assign("/login")}>Log in</button>
      </>
    );
  }
  return (
    <>
      <button onClick={logout}>Log out</button>

      <Avatar {...stringAvatar(`${user?.displayName || user.email}`)} />
    </>
  );
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  console.log(name);
  let label = name[0];
  try {
    label = name.split(" ")[0][0] + name.split(" ")[1][0];
  } catch {
    //just let it be
  }

  return {
    sx: {
      textTransform: "uppercase",
      bgcolor: stringToColor(name),
    },
    children: `${label}`,
  };
}
