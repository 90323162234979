import * as React from "react";
import { Foot, IconButton } from "../styles";
import {
  FacebookSharp,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from "@mui/icons-material";

export const Footer = () => {
  return (
    <Foot>
      <span style={{ marginRight: `auto` }}>
        © 2023 Stream Ocean. All rights reserved.
      </span>
      <a
        className="textLink"
        href="mailto:info@streamocean.io?subject=Hi%20there"
      >
        Contact us
      </a>
      <a className="textLink" href="https://www.streamocean.io/">
        Home
      </a>
      <a href="https://www.linkedin.com/company/stream-ocean/">
        <IconButton>
          <LinkedIn />
        </IconButton>
      </a>
      <a href="https://www.instagram.com/streamocean/">
        <IconButton>
          <Instagram />
        </IconButton>
      </a>
      <a href="https://www.facebook.com/streamocean.io/">
        <IconButton>
          <FacebookSharp />
        </IconButton>
      </a>
      <a href="https://twitter.com/streamocean_io">
        <IconButton>
          <Twitter />
        </IconButton>
      </a>
      <a href="https://www.youtube.com/@streamocean/videos">
        <IconButton>
          <YouTube />
        </IconButton>
      </a>
    </Foot>
  );
};
