import * as React from "react";
import { useState } from "react";
import { CenteredPage, GlobalStyle, MainContent, MainPage } from "./styles";
import { BrowserRouter } from "react-router-dom";
import * as ReactDOM from "react-dom";
import { NavHeader } from "./components/Navigation";
import { Footer } from "./components/Footer";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "@fontsource/roboto-mono";
import "@fontsource/numans";
import { ToastContainer } from "react-toastify";
import { Outlet, Route, Routes } from "react-router-dom";
import { PillButtonDense } from "./styles";
import { MediaDetailPage } from "./components/sentinel/MediaDetail/MediaDetail";
import {
  SentinelContainer,
  SentinelNavHeader,
} from "./components/sentinel/Navigation";
import { SvgLibrary } from "./components/sentinel/SvgLibrary";
import { SentinelFooter } from "./components/sentinel/Footer";
import { ProjectDirectory } from "./components/ProjectDirectory/ProjectDirectory";
import { ProjectDetail } from "./components/ProjectDetail/ProjectDetail";
import { LogIn, Register, ResetPassword } from "./components/LogIn";

Sentry.init({
  dsn: "https://95c85eb3789f46748498f61b08848d9d@o4504400290447360.ingest.sentry.io/4504400291889152",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const NCFScoreboard = () => {
  // create auth context
  // let auth = GetAuthInfo()
  // const [isNavVisible, setIsNavVisible] = useState(true);
  // let location = window.location;
  // let path = "/"+location.pathname.split("/")[1];
  //
  // useEffect(()=>{
  //
  //     for (let p in EXEMPT_URLS) {
  //         if (EXEMPT_URLS[p] == path) {
  //             setIsNavVisible(false);
  //             return
  //         }
  //     }
  //     setIsNavVisible(true);
  // },[])

  return (
    <BrowserRouter>
      <DashboardRoutes />
    </BrowserRouter>
  );
};

const LegacyTemplate = () => {
  return (
    <CenteredPage>
      <GlobalStyle />
      {/*<AuthContext.Provider value={auth}>*/}

      {/*<MobileDetector />*/}
      {/*{isNavVisible && <NavHeader />}*/}
      <NavHeader />
      {/*<LoggedIn />*/}
      <MainPage>
        {/*{isNavVisible && */}
        {/*<>*/}
        {/*  <NavToggleButton show={showNav} setShow={setShowNave} />*/}
        {/*  <Navigation show={showNav} />*/}
        {/*</>}*/}

        <MainContent>
          <Outlet />
        </MainContent>
      </MainPage>
      {<Footer />}
      {/*<ToastContainer />*/}
      {/*  Toast container is not rendering properly for some reason*/}
      {/*</AuthContext.Provider>*/}
    </CenteredPage>
  );
};

const SentinelTemplate = () => {
  const [theme, setTheme] = useState<string>("Light");
  return (
    <CenteredPage>
      <GlobalStyle />
      <SvgLibrary />
      <SentinelContainer data-theme={theme}>
        <PillButtonDense
          onClick={() => {
            setTheme("Light");
          }}
        >
          Light
        </PillButtonDense>
        <PillButtonDense
          onClick={() => {
            setTheme("Dark");
          }}
        >
          Dark
        </PillButtonDense>

        {/*<AuthContext.Provider value={auth}>*/}

        {/*<MobileDetector />*/}
        {/*{isNavVisible && <NavHeader />}*/}
        <SentinelNavHeader />
        {/*<LoggedIn />*/}
        <MainPage>
          {/*{isNavVisible && */}
          {/*<>*/}
          {/*  <NavToggleButton show={showNav} setShow={setShowNave} />*/}
          {/*  <Navigation show={showNav} />*/}
          {/*</>}*/}

          <MainContent>
            <Outlet />
          </MainContent>
        </MainPage>
        <SentinelFooter />
      </SentinelContainer>
      {/*<ToastContainer />*/}
      {/*  Toast container is not rendering properly for some reason*/}
      {/*</AuthContext.Provider>*/}
    </CenteredPage>
  );
};

export const Paths = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  RESET: "/reset",

  // start NC routes here

  // TODO: consider adding media list
  MEDIA_DETAIL: "/media/:id",
  PROJECTS: "/projects",
  PROJECT_DETAIL: "/project/:id",
};

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SentinelTemplate />}>
        <Route path={Paths.MEDIA_DETAIL} element={<MediaDetailPage />} exact />
      </Route>
      <Route path="/" element={<LegacyTemplate />}>
        <Route path={Paths.LOGIN} element={<LogIn />} exact />
        <Route path={Paths.PROJECTS} element={<ProjectDirectory />} exact />
        <Route path={Paths.PROJECT_DETAIL} element={<ProjectDetail />} exact />
        <Route path={Paths.REGISTER} element={<Register />} exact />
        <Route path={Paths.RESET} element={<ResetPassword />} exact />
      </Route>
    </Routes>
  );
};

ReactDOM.render(<NCFScoreboard />, document.getElementById("app"));
