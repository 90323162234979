import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { MetricsTimeSeriesResponse } from "../../types";
import { FetchLatestDeploymentMetrics } from "../../api/general";
import { DateTime } from "luxon";
import {
  ChartContainer,
  ChartNavContainer,
  DivLabel,
  FocusDivRow,
  IndicatorDiv,
} from "./styles";
import { ProjectInfo } from "../../types";
import { StatusIcon } from "./ProjectDetail";
import { BarFromSeries, LineFromSeries } from "../Charts/BarAndLine";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { PillButtonDense, Theme } from "../../styles";

const TIME_FORMAT: number = "HH:mm"; // 24-hour time

const DAYS_IN_SECONDS: number = 24 * 60 * 60;

enum MetricsResolution {
  Hourly = 1,
  Daily,
}

enum MetricsDuration {
  Day = 1 * DAYS_IN_SECONDS,
  Week = 7 * DAYS_IN_SECONDS,
  Month = 30 * DAYS_IN_SECONDS,
  Year = 365 * DAYS_IN_SECONDS,
  Max = 1e6 * DAYS_IN_SECONDS, // An arbitrarily long time.
}

// Description of what metrics are desired and/or provided.
type MetricsSpec = {
  resolution: MetricsResolution;
  duration: MetricsDuration;
};

type LoadedMetrics = {
  spec: MetricsSpec;
  response: MetricsTimeSeriesResponse;
};

export const Metrics = (props: {
  project: ProjectInfo;
  selectedDeploymentId: number | null;
}) => {
  const [metricsDaily, setMetricsDaily] = useState<LoadedMetrics | null>(null);
  const [metricsHourly, setMetricsHourly] = useState<LoadedMetrics | null>(
    null,
  );
  const [chartSpec, setChartSpec] = useState<MetricsSpec>({
    resolution: MetricsResolution.Hourly,
    duration: MetricsDuration.Day,
  });
  const [user, loading, error] = useAuthState(auth);

  // Fetches daily metrics.
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!props.selectedDeploymentId) {
      return;
    }

    const now = DateTime.now();
    const then = now.minus({ months: 1 });

    const resolution = MetricsResolution.Daily;

    // We require at least a week of daily data to support
    // the weekly metrics in the ValueBoxes.
    const duration =
      chartSpec.duration < MetricsDuration.Week
        ? MetricsDuration.Week
        : chartSpec.duration;

    FetchLatestDeploymentMetrics(
      user,
      props.selectedDeploymentId,
      duration,
      2,
    ).then((m) => {
      if (m) {
        setMetricsDaily({
          spec: {
            resolution: resolution,
            duration: duration,
          },
          response: m,
        });
      }
    });
  }, [props.selectedDeploymentId, chartSpec, user]);

  function getLatestDate(metric: string): [DateTime] | null {
    if (!metricsDaily?.response?.metrics) {
      return null;
    }
    const metrics = metricsDaily.response.metrics;
    const interval = metricsDaily.response.interval;
    for (let m = 0; m < metrics.length; m++) {
      if (metrics[m].metric == metric) {
        const valueMap = metrics[m].value_map;
        let lastIndex = -1;
        for (let d = 0; d < valueMap.length; d++) {
          // Look for the latest datum for any taxon.
          lastIndex = Math.max(lastIndex, valueMap[d].value_list.length - 1);
        }
        return lastIndex >= 0
          ? DateTime.fromISO(interval[lastIndex], { setZone: true })
          : null;
      }
    }
    return null;
  }

  // Fetches hourly metrics
  useEffect(() => {
    if (!user) {
      return;
    }

    if (props.selectedDeploymentId == null) {
      return;
    }

    const resolution = MetricsResolution.Hourly;
    const duration = MetricsDuration.Day;

    FetchLatestDeploymentMetrics(
      user,
      props.selectedDeploymentId,
      duration,
      resolution,
    ).then((mh) => {
      setMetricsHourly({
        spec: {
          resolution: resolution,
          duration: duration,
        },
        response: mh,
      });
    });
  }, [props.selectedDeploymentId, user]);

  // Determines the fractional change (% change / 100) of newer relative to older.
  const getFractionalChange = (older: number, newer: number): number => {
    return (newer - older) / older;
  };

  const getDailyMeanCount = () => {
    let vals = getGivenMetric(metricsDaily?.response, "MeanCt");
    if (!vals) {
      return undefined;
    }

    let latestDate = getLatestDate("MeanCt");
    if (latestDate == null) {
      return undefined;
    }

    return (
      <>
        <span className={"value"}>{vals[vals.length - 1].toFixed(1)}</span>
        {
          <StatusIcon
            updating={latestDate < DateTime.now().minus({ days: 1 })}
            updatedDate={latestDate}
          />
        }
      </>
    );
  };

  const getDailyMeanCountDelta = () => {
    let vals = getGivenMetric(metricsDaily?.response, "MeanCt");
    if (!vals) {
      return undefined;
    }

    const change = getFractionalChange(
      vals[vals.length - 2],
      vals[vals.length - 1],
    );
    if (!isFinite(change)) {
      return undefined;
    }

    let latestDate = getLatestDate("MeanCt");
    if (latestDate == null) {
      return undefined;
    }

    return (
      <>
        <span className={"value"}>
          {(change > 0 ? "+" : "") + (change * 100).toFixed(1)}%
        </span>
        {
          <StatusIcon
            updating={latestDate < DateTime.now().minus({ days: 1 })}
            updatedDate={latestDate}
          />
        }
      </>
    );
  };

  const getWeeklyMeanCount = () => {
    // we know if metricsDaily is null then so is latestValue
    let meanCts = getGivenMetric(metricsDaily?.response, "MeanCt");
    if (meanCts == null) {
      // then we don't have a week of data to fill in here
      return undefined;
    }

    let totalMeanCt = 0;
    let dataPoints = 0;
    for (let i = meanCts.length - 7; i < meanCts.length; i++) {
      if (meanCts[i] != null) {
        totalMeanCt += meanCts[i];
        dataPoints++;
      }
    }

    if (dataPoints == 0) {
      return undefined;
    }

    let latestDate = getLatestDate("MeanCt");
    if (latestDate == null) {
      return undefined;
    }

    return (
      <>
        <span className={"value"}>{(totalMeanCt / dataPoints).toFixed(1)}</span>
        {
          <StatusIcon
            updating={latestDate < DateTime.now().minus({ days: 1 })}
            updatedDate={latestDate}
          />
        }
      </>
    );
  };

  const TimeRange = (props: { start: DateTime; end: DateTime }) => {
    return (
      <>
        {props.start.toFormat(TIME_FORMAT)}-{props.end.toFormat(TIME_FORMAT)}
      </>
    );
  };

  const getHourOfMaxMeanCount = () => {
    // we want the largest hourly value from all the hourly values
    if (metricsHourly == null || !metricsHourly.response.metrics) {
      return undefined;
    }
    let maxVal: number | null = null;
    let maxHr: DateTime | null = null;
    let vals = getGivenMetric(metricsHourly.response, "MeanCt");
    if (!vals) {
      return undefined;
    }
    for (let vv = 0; vv < vals.length; vv++) {
      if (maxVal == null || vals[vv] > maxVal) {
        maxVal = vals[vv];
        maxHr = DateTime.fromISO(metricsHourly.response.interval[vv], {
          setZone: true,
        });
      }
    }

    if (!maxHr) {
      return undefined;
    }

    return (
      <>
        <span className={"wideValue"}>
          <TimeRange start={maxHr} end={maxHr.plus({ hours: 1 })} />
        </span>
        {
          <StatusIcon
            updating={maxHr < DateTime.now().plus({ days: 1 })}
            updatedDate={maxHr}
          />
        }
      </>
    );
  };
  const getHourOfMaxMaxN = () => {
    // we want the largest hourly value from all the hourly values
    if (metricsHourly == null || !metricsHourly.response.metrics) {
      return undefined;
    }
    let maxVal: number | null = null;
    let maxHr: DateTime | null = null;
    let vals = getGivenMetric(metricsHourly.response, "MaxN");
    if (!vals) {
      return undefined;
    }
    for (let vv = 0; vv < vals.length; vv++) {
      if (maxVal == null || vals[vv] > maxVal) {
        maxVal = vals[vv];
        maxHr = DateTime.fromISO(metricsHourly.response.interval[vv], {
          setZone: true,
        });
      }
    }

    if (!maxHr) {
      return undefined;
    }

    return (
      <>
        <span className={"wideValue"}>
          <TimeRange start={maxHr} end={maxHr.plus({ hours: 1 })} />
        </span>
        {
          <StatusIcon
            updating={maxHr < DateTime.now().minus({ days: 1 })}
            updatedDate={maxHr}
          />
        }
      </>
    );
  };

  const getMaxHourlyMaxN = () => {
    // we want the largest hourly value from all the hourly values
    // todo abstract this with above func
    if (metricsHourly == null || !metricsHourly.response.metrics) {
      return undefined;
    }
    let maxVal: number | null = null;
    let maxHr: DateTime | null = null;
    let vals = getGivenMetric(metricsHourly.response, "MaxN");
    if (!vals) {
      return undefined;
    }
    for (let vv = 0; vv < vals.length; vv++) {
      if (maxVal == null || vals[vv] > maxVal) {
        maxVal = vals[vv];
        maxHr = DateTime.fromISO(metricsHourly.response.interval[vv], {
          setZone: true,
        });
      }
    }

    if (!maxVal || !maxHr) {
      return undefined;
    }

    return (
      <>
        <span className={"value"}>{maxVal}</span>
        {
          <StatusIcon
            updating={maxHr < DateTime.now().minus({ days: 1 })}
            updatedDate={maxHr}
          />
        }
      </>
    );
  };

  const getChartData = (
    metrics: MetricsTimeSeriesResponse,
    metricTypes: Array<string>,
    timeFormat: string,
  ) => {
    const vals = [];

    if (!metrics?.response?.metrics) {
      return vals;
    }

    const dataMap = new Map<string, Array<number>>();
    for (let metricType of metricTypes) {
      dataMap.set(metricType, getGivenMetric(metrics.response, metricType));
      if (!dataMap.get(metricType)) {
        return [];
      }
    }

    let metricsLength = 0;
    for (let data of dataMap.values()) {
      metricsLength = Math.min(
        Math.max(metricsLength, Number.MAX_VALUE),
        data.length,
      );
    }

    for (let d = 0; d < metricsLength; d++) {
      const val = {
        label: DateTime.fromISO(metrics.response.interval[d], {
          setZone: true,
        }).toFormat(timeFormat),
      };
      for (let metricType of metricTypes) {
        val[metricType] = dataMap.get(metricType)[d];
      }
      vals.push(val);
    }
    return vals;
  };

  const getDateFormat = (duration: MetricsDuration) => {
    switch (duration) {
      case MetricsDuration.Day:
        return TIME_FORMAT;
      case MetricsDuration.Week:
        return "ccc d";
      case MetricsDuration.Month:
      case MetricsDuration.Year:
        return "MMM d";
      case MetricsDuration.Max:
        return "y-MM-dd";
    }
  };

  const getChartNav = () => {
    return (
      <ChartNavContainer>
        <PillButtonDense
          selected={chartSpec.duration == MetricsDuration.Day}
          onClick={() => {
            setChartSpec({
              resolution: MetricsResolution.Hourly,
              duration: MetricsDuration.Day,
            });
          }}
        >
          1D
        </PillButtonDense>
        <PillButtonDense
          selected={chartSpec.duration == MetricsDuration.Week}
          onClick={() => {
            setChartSpec({
              resolution: MetricsResolution.Daily,
              duration: MetricsDuration.Week,
            });
          }}
        >
          7D
        </PillButtonDense>
        <PillButtonDense
          selected={chartSpec.duration == MetricsDuration.Month}
          onClick={() => {
            setChartSpec({
              resolution: MetricsResolution.Daily,
              duration: MetricsDuration.Month,
            });
          }}
        >
          1M
        </PillButtonDense>
        <PillButtonDense
          selected={chartSpec.duration == MetricsDuration.Year}
          onClick={() => {
            setChartSpec({
              resolution: MetricsResolution.Daily,
              duration: MetricsDuration.Year,
            });
          }}
        >
          1Y
        </PillButtonDense>
      </ChartNavContainer>
    );
  };

  const getChart = () => {
    let label;
    let metrics;
    if (chartSpec.resolution == MetricsResolution.Hourly) {
      label = "Fish Tracked by Hour";
      metrics = metricsHourly;
    } else {
      label = "Fish Tracked by Day";
      metrics = metricsDaily;
    }
    const data = getChartData(
      metrics,
      ["MaxN", "MeanCt"],
      getDateFormat(metrics?.spec?.duration),
    );

    return (
      <ChartContainer id={"charts"}>
        {getChartNav()}
        <DivLabel>{label}</DivLabel>
        <LineFromSeries
          m={data}
          lines={[
            {
              dataKey: "MaxN",
              label: "Max count",
              color: Theme.colors.blue,
            },
            {
              dataKey: "MeanCt",
              label: "Mean count",
              color: Theme.colors.orange,
            },
          ]}
        />
      </ChartContainer>
    );
  };

  return (
    <>
      <FocusDivRow style={{ flexWrap: `nowrap` }}>
        <ValueBox
          bg={"/public/fishIcon.svg"}
          label={"Daily Mean Count"}
          title={
            "The arithmetic mean of fish sightings in a frame over the last full day of data."
          }
          value={getDailyMeanCount()}
        />
        <ValueBox
          bg={"/public/timeIcon.svg"}
          label={"Hour of Greatest Mean Count"}
          title={
            "The hour range with the highest arithmetic mean of fish sightings over the last full day of data."
          }
          value={getHourOfMaxMeanCount()}
        />
        <ValueBox
          bg={"/public/eyesIcon.svg"}
          label={"Weekly Mean Count"}
          title={
            "The arithmetic mean of fish sightings in a frame over the last 7 full days of data."
          }
          value={getWeeklyMeanCount()}
        />
      </FocusDivRow>
      <FocusDivRow style={{ flexWrap: `nowrap` }}>
        <ValueBox
          bg={"/public/fishIcon.svg"}
          label={"Daily Max Count"}
          title={
            "The maximum number of fish sightings recorded in a frame over the last full day of data."
          }
          value={getMaxHourlyMaxN()}
        />
        <ValueBox
          bg={"/public/timeIcon.svg"}
          label={"Hour of Daily Max Count"}
          title={
            "The hour range with the maximum number of fish sightings recorded in a frame over the last full day of data."
          }
          value={getHourOfMaxMaxN()}
        />
        <ValueBox
          bg={"/public/overlapIcon.svg"}
          label={"Daily Change in Mean Count"}
          title={
            "The difference between Daily Mean Counts from the last full day of data and the preceding day, expressed as a percentage."
          }
          value={getDailyMeanCountDelta()}
        />
      </FocusDivRow>

      {getChart()}
    </>
  );
};

const ValueBox = (props: {
  label: string;
  title: string;
  bg: string;
  value?: ReactElement;
}) => {
  return (
    <IndicatorDiv $backgroundImg={props.bg} title={props.title}>
      <DivLabel>{props.label}</DivLabel>
      {props.value ? props.value : `no data`}
    </IndicatorDiv>
  );
};

function getGivenMetric(
  timeseriesMetrics: MetricsTimeSeriesResponse,
  metric: string,
): number[] | null {
  if (timeseriesMetrics?.metrics == null) {
    return null;
  }
  for (let m = 0; m < timeseriesMetrics.metrics.length; m++) {
    if (timeseriesMetrics.metrics[m].metric == metric) {
      return sumTaxa(timeseriesMetrics.metrics[m].value_map);
    }
  }
  return null;
}

function sumTaxa(valueMaps: MetricsValueMap[]): number[] | null {
  const result = [];
  for (let vs = 0; vs < valueMaps.length; vs++) {
    for (let i = 0; i < valueMaps[vs].value_list.length; i++) {
      if (result[i] == null) {
        result[i] = valueMaps[vs].value_list[i];
      } else {
        result[i] += valueMaps[vs].value_list[i];
      }
    }
  }
  return result;
}
