import * as React from "react";

import { ProjectInfo } from "../../types";
import { DivLabel, StreamPreviewRow, StreamsDiv } from "./styles";
import { PillButtonDense, Theme } from "../../styles";
import {
  ContentType,
  FetchLatestMedia,
  Media,
  MediaType,
} from "../../api/general";
import { useEffect, useRef, useState } from "react";
import { Box, Checkbox, CheckboxProps, Rating } from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  useGridApiContext,
  GridFooterContainerProps,
  GridSelectionModel,
  GridFooterContainer,
} from "@mui/x-data-grid";
import prettyBytes from "pretty-bytes";
import { DateTime } from "luxon";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function renderRating(params: GridRenderCellParams<number>) {
  // get localstorage value if any
  let val = localStorage.getItem(params.row.id);
  let value = params.value;
  if (val) {
    value = Number(val);
  }

  return <Rating sx={{ color: Theme.colors.white }} readOnly value={value} />;
}
//
// function renderTime(params: GridRenderCellParams<number>) {
//     return <GridCell value={params.value} />;
// }

const renderRatingEditInputCell: GridColDef["renderCell"] = (
  params: GridRenderCellParams<number>,
) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number | null,
  ) => {
    // set localstorage rating
    localStorage.setItem(params.row.id, String(newValue));
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const handleRef = (element: HTMLSpanElement) => {
    if (element) {
      const input = element.querySelector<HTMLInputElement>(
        `input[value="${value}"]`,
      );
      input?.focus();
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", pr: 2 }}>
      <Rating
        ref={handleRef}
        name="rating"
        precision={1}
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};

// bgcolor: Theme.colors.black, stroke: Theme.colors.white, fill: Theme.colors.white

const StyledCheckbox = React.forwardRef<Ref, CheckboxProps>((props, ref) => {
  return <Checkbox ref={ref} sx={{ color: Theme.colors.white }} {...props} />;
});

const VideoWithControls = (props: { src: string }) => {
  const vid = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
  }, [props.src]);

  return (
    <>
      <video ref={vid} src={props.src} controls />
    </>
  );
};

export const VideoArchive = (props: {
  project: ProjectInfo;
  selectedDeploymentId: number | null;
}) => {
  // todo: default datestart should be 24 hours ago, dateend now
  const [contentType, setContentType] = useState<ContentType | undefined>(4);
  const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
  const [media, setMedia] = useState<Media[]>([]);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeVideo, setActiveVideo] = useState("");
  const [selectedVids, setSelection] = useState<GridSelectionModel>([]);
  const [user, authLoading, error] = useAuthState(auth);

  function StyledFooter(props: GridFooterContainerProps) {
    return (
      <GridFooterContainer
        sx={{
          display: `flex`,
          border: `none`,
          padding: `0 10px`,
          gap: `32px`,
          justifyContent: `flex-start`,
        }}
        {...props}
      >
        {/*{selectedVids && selectedVids.length > 0 ?*/}
        {/*    <>*/}
        <PillButtonDense selected={false}>
          {`${selectedVids.length} row${
            selectedVids.length > 1 || !selectedVids || selectedVids.length == 0
              ? `s`
              : ``
          }`}
        </PillButtonDense>
        <PillButtonDense
          disabled={!selectedVids || selectedVids.length == 0}
          onClick={downloadSelection}
          selected={!loading}
        >
          {loading ? "Downloading ..." : "Download"}
        </PillButtonDense>

        {/*</>*/}
        {/*    :*/}
        {/*    ``*/}
        {/*}*/}
      </GridFooterContainer>
    );
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    if (props.selectedDeploymentId == null) {
      return;
    }
    setFetching(true);
    try {
      const now = DateTime.now();
      FetchLatestMedia(user, {
        project_id: props.project.id,
        deployment_id: props.selectedDeploymentId,
        count: 5,
        content_type: contentType,
      }).then((m) => {
        if (m) {
          setMedia(m);
          setActiveVideo(m[0].url);
        } else {
          setMedia([]);
        }
      });
    } catch (err) {
      alert(err);
    }
    setFetching(false);
  }, [contentType, mediaType, props.selectedDeploymentId, user]);

  const columns: GridColDef[] = [
    {
      headerClassName: "so-media-theme--Header",
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
    },
    {
      field: "url",
      headerName: "Url",
      hide: true,
      width: 220,
      editable: false,
      headerClassName: "so-media-theme--Header",
    },
    {
      field: "name",
      headerName: "Name",
      width: 220,
      editable: false,
      valueGetter: (value, row, column, apiRef) => row.id,
      headerClassName: "so-media-theme--Header",
    },
    {
      field: "time",
      headerName: "Created",
      width: 300,
      editable: false,
      headerClassName: "so-media-theme--Header",
      valueFormatter: (value, row, column, apiRef) =>
        `${DateTime.fromISO(value, { setZone: true }).toFormat(
          "D",
        )} ${DateTime.fromISO(value, { setZone: true }).toFormat("HH:mm:ss")}`,
    },
    // {
    //     field: 'length',
    //     headerName: 'Length',
    //     type: 'dateTime',
    //     width: 110,
    //     editable: false,
    // },
    {
      field: "size",
      headerName: "Size",
      width: 160,
      headerClassName: "so-media-theme--Header",
      valueGetter: (value, row, column, apiRef) => prettyBytes(row.size),
    },
    {
      field: "rating",
      headerName: "Rating",
      description: "Double-click cell to rate a video",
      headerClassName: "so-media-theme--Header",
      renderCell: renderRating,
      renderEditCell: renderRatingEditInputCell,
      editable: true,
      width: 140,
    },
  ];

  function onSelectionChange(newSelection: GridSelectionModel) {
    setSelection(newSelection);
  }

  async function downloadSelection() {
    if (loading) {
      return;
    }
    setLoading(true);
    await downloadFile(selectedVids as string[]);
    setLoading(false);
  }

  return (
    <StreamsDiv id={"streams"}>
      <DivLabel>Stream Archive</DivLabel>

      <StreamPreviewRow>
        {/*<LargeFocusDiv style={{margin: `0 auto`}}>*/}
        <VideoWithControls src={activeVideo} />

        {/*</LargeFocusDiv>*/}
      </StreamPreviewRow>

      <Box sx={{ height: 370, width: "100%" }}>
        <DataGrid
          // classes={{sortIcon: "sortIcon"}}
          sx={{
            color: Theme.colors.white,
            fontFamily: Theme.fonts.subtitle,
            fontSize: `12px`,
            border: `none`,
            borderTop: `1px solid ${Theme.colors.white}`,
            borderRadius: 0,
            "& .so-media-theme--Header": {
              bgcolor: Theme.colors.black,
              color: Theme.colors.white,
              fill: Theme.colors.white,
              stroke: Theme.colors.white,
              // '&.svg':  {
              //     color: Theme.colors.white,
              //     fill: Theme.colors.white,
              //     stroke: Theme.colors.white,
              // }
            },
            "& .so-media-theme--Selected": {
              bgcolor: Theme.colors.darkPink,
              color: Theme.colors.white,
              "&:hover": {
                bgcolor: Theme.colors.pink,
              },
              "&.svg": {
                color: Theme.colors.white,
                fill: Theme.colors.white,
                stroke: Theme.colors.white,
              },
            },
            "& .so-media-theme--Unselected": {
              bgcolor: Theme.colors.black,
              color: Theme.colors.white,
              "&:hover": {
                bgcolor: Theme.colors.pink,
              },
              "&.svg": {
                color: Theme.colors.white,
              },
            },
            "& .MuiDataGrid-filler": {
              bgcolor: Theme.colors.black,
            },
          }}
          rows={media}
          columnTypes={{
            [GRID_CHECKBOX_SELECTION_COL_DEF.type]:
              GRID_CHECKBOX_SELECTION_COL_DEF,
          }}
          columns={columns}
          getRowId={(row) => row.url}
          pageSize={10}
          rowsPerPageOptions={[5]}
          onRowClick={(data) => setActiveVideo(data.row.url)}
          checkboxSelection
          disableSelectionOnClick
          onRowSelectionModelChange={onSelectionChange}
          experimentalFeatures={{ newEditingApi: true }}
          getRowClassName={(params) =>
            `so-media-theme--${
              activeVideo == params.row.url ? "Selected" : "Unselected"
            }`
          }
          slots={{ baseCheckbox: StyledCheckbox, footer: StyledFooter }}
        />
      </Box>
    </StreamsDiv>
  );
};

async function downloadFile(fileURIs: string[]): Promise<void> {
  var link = document.createElement("a");
  document.body.appendChild(link);

  for (let i = 0; i < fileURIs.length; i++) {
    link.setAttribute("href", fileURIs[i]);
    link.click();
    await new Promise((r) => setTimeout(r, 1000));
    // await setTimeout((v:any)=> console.log("downloading "+fileURIs[i]), 1000)
  }

  // link.download = fileURI.split("/")[fileURI.split("/").length-1];
  // link.href = fileURI;
  // link.click();
  document.body.removeChild(link);
  return Promise.resolve();
}
