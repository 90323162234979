import styled from "styled-components";
import { Theme } from "../../styles";

export const ProjectDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  padding: 80px;
  justify-content: stretch;
`;

export const ProjectDetailHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  color: ${Theme.colors.pink};
  align-items: stretch;
  gap: 24px;
  font-size: 12px;
  font-weight: 400;

  div.infoBox {
    border: 1px solid ${Theme.colors.pink};
    border-radius: 8px;
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    padding: 12px 12px 12px 16px;
    justify-content: center;
    flex-grow: 1;
    box-sizing: border-box;
    height: 81px;
    flex-shrink: 0;
    gap: 8px;
    .head {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export const DeploymentSelector = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  right: 0;
  top: 0;
  gap: 8px;
  padding: 12px 12px 12px 16px;
  justify-content: center;
  div.choices {
    display: flex;
    flex-direction: column;
    width: 300px;
    position: absolute;
    background-color: ${Theme.colors.black};
    top: -1px;
    right: -1px;
    border: 1px solid ${Theme.colors.pink};
    box-sizing: border-box;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
    z-index: 1;
    div.choice {
      display: flex;
      flex-direction: column;
      height: 80px;
      width: 100%;
      gap: 8px;
      box-sizing: border-box;
      border-bottom: 1px solid ${Theme.colors.pink};
      justify-content: center;
      padding: 12px 12px 12px 16px;
      :hover {
        background-color: ${Theme.colors.pink};
        color: ${Theme.colors.black};
      }
    }
    div.choice:last-child {
      border-bottom: none;
    }
  }
  .mainContent {
    :hover {
      background-color: ${Theme.colors.pink};
      color: ${Theme.colors.black};
    }
  }
  .arrow {
    position: absolute;
    right: 17px;
    top: 32px;
  }
`;

export const ProjectTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;
  height: 74px;
`;

export const DivLabel = styled.span`
  position: absolute;
  top: 16px;
  left: 16px;
  font-family: ${Theme.fonts.body};
  color: ${Theme.colors.white};
  font-weight: 400;
  font-size: 12px;
`;

export const DivSubtitle = styled.span`
  position: absolute;
  bottom: 0px;
  right: 4px;
  font-family: ${Theme.fonts.body};
  color: ${Theme.colors.white};
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

export const DivMessage = styled.span`
  margin: auto;
  font-family: ${Theme.fonts.body};
  color: ${Theme.colors.white};
  font-weight: 400;
  font-size: 12px;
`;

export const FocusDivRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  color: ${Theme.colors.pink};
  justify-content: space-around;
  gap: 24px;
  margin-bottom: 24px;
`;

export const LargeFocusDiv = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 1;
  flex-basis: 1;
  box-sizing: border-box;
  width: 500px;
  min-width: 200px;
  max-width: 720px;
  height: 360px;
  overflow: hidden;
  border: 1px solid ${Theme.colors.white};
  border-radius: 8px;
`;

export const YoutubeDiv = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1;
  box-sizing: border-box;
  width: 640px;
  height: 360px;
  max-width: 640px;
  overflow: hidden;
  border: 1px solid ${Theme.colors.white};
  border-radius: 8px;
`;

export const SmallFocusDiv = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1;
  box-sizing: border-box;
  width: 500px;
  min-width: 200px;
  max-width: 630px;
  height: 352px;
  overflow: hidden;
  border: 1px solid ${Theme.colors.white};
  border-radius: 8px;
  video,
  iframe {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const IndicatorDiv = styled.div<{ $backgroundImg: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: 282px;
  max-width: 382px;
  flex-grow: 1;
  flex-basis: 0;
  height: 240px;
  text-align: center;
  border: 1px solid ${Theme.colors.white};
  border-radius: 8px;
  position: relative;
  justify-self: stretch;
  .value {
    font-size: 80px;
  }
  .wideValue {
    font-size: 50px;
  }
  background-image: url(${(props) => props.$backgroundImg});
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${Theme.colors.white};
  position: relative;
  height: 488px;
  align-items: center;
  justify-content: center;
  font-family: ${Theme.fonts.subtitle};
  margin-bottom: 32px;
`;

export const ChartNavContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 32px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;
  height: 74px;
`;

export const StreamsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-top: 49px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${Theme.colors.white};
  position: relative;
  h4.subtitle {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 16px;
    width: 100%;
    height: 49px;
    font-weight: 500;
    font-size: 16px;
    background-color: ${Theme.colors.white};
    color: ${Theme.colors.black};
    font-family: ${Theme.fonts.subtitle};
  }
`;

export const StreamPreviewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  div.media {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
    justify-content: center;
    max-height: 352px;
    div {
      margin: 0 32px 32px 32px;
    }
  }
  video {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px solid ${Theme.colors.white};
    border-bottom: 1px solid ${Theme.colors.white};
  }
  button.leftright {
    width: 48px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: ${Theme.colors.white};
    border-radius: 100px;
    color: ${Theme.colors.black};
    :hover {
      background-color: ${Theme.colors.pink};
    }
  }
`;

export const VideoTabsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 0 8px;
  flex-direction: row;
  margin-top: 0px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
  gap: 8px;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 10px;
  img {
    width: 100%;
    margin: 10px 0;
  }
  div.row {
    margin: 5px 0;
    div.icon {
      width: 30px;
    }
    display: flex;
    flex-direction: row;
  }

  div.row:nth-child(4n + 1) {
    div.icon {
      color: ${Theme.colors.blue};
    }
  }
  div.row:nth-child(4n + 2) {
    div.icon {
      color: ${Theme.colors.pink};
    }
  }
  div.row:nth-child(4n + 3) {
    div.icon {
      color: ${Theme.colors.white};
    }
  }
  div.row:nth-child(4n) {
    div.icon {
      color: ${Theme.colors.purple};
    }
  }
`;

export const TwoThirdsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(65% - 20px);
  padding: 10px;
  overflow: hidden;
`;

export const OneThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(35% - 20px);
  padding: 10px;
`;

// only to be used to contain 1/3,2/3, or text containers
export const RowNoPadding = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0;
  div.row {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
`;

export const GalleryFilters = styled.div`
  background-color: ${Theme.colors.pink};
  width: 150px;
  height: 60vh;
  color: ${Theme.colors.white};
  display: flex;
  padding: 10px;
  flex-shrink: 0;
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 60vh;
  width: 100%;
  img,
  video {
    margin: 10px;
  }
  padding: 10px;

  h2 {
    width: calc(100% - 10px);
    margin: 5px;
    text-transform: none;
    color: ${Theme.colors.pink};
    font-weight: normal;
  }
`;

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Theme.colors.pink};
  p {
    padding: 0 10px;
    margin: 0;
  }
`;
