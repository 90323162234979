import styled from "styled-components";
import { Theme } from "../../styles";

export const DirectoryContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  margin: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
`;

export const DirectoryListing = styled.div`
  width: 300px;
  padding: 10px;
  margin: 12px;
  border: 1px solid ${Theme.colors.white};
  border-radius: 8px;
  img {
    width: 100%;
  }
  &:hover {
    border: 4px solid ${Theme.colors.blue};
    margin: 9px;
  }
  transition: all ease 0.5s 0.2s;
`;
