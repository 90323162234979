import * as React from "react";
import { useParams } from "react-router-dom";
import {
  DeploymentSelector,
  DivSubtitle,
  FocusDivRow,
  ProjectDetailContainer,
  ProjectDetailHeaderDiv,
  ProjectTabsContainer,
} from "./styles";
import { useEffect, useState } from "react";
import { FetchProjectInfo } from "../../api/general";
import { ProjectInfo, Camera, Deployment } from "../../types";
import { toast } from "react-toastify";
import { PillButton, Theme } from "../../styles";
import { VideoArchive } from "./VideoArchive";
import { ProjectMap } from "./ProjectMap";
import { DeploymentDetail } from "./DeploymentDetail";
import { GlobalPosition } from "./GlobalPosition";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import styled from "styled-components";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { DateTime } from "luxon";
import { Metrics } from "./Metrics";
import { FeaturedHighlight } from "./FeaturedHighlight";
import { ArrowIcon } from "../arrowIcon";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Theme.colors.white,
    color: Theme.colors.black,
    maxWidth: 220,
    fontFamily: Theme.fonts.subtitle,
    fontSize: `12px`,
    borderRadius: `2px`,
  },
}));

export const ProjectDetail = () => {
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);
  let id: string;
  ({ id } = useParams());
  const [deployment, setDeployment] = useState<Deployment | null>(null);
  const [showSelect, setShowSelect] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (!user) {
      return;
    }
    // fetch project info on page load
    FetchProjectInfo(user, Number(id))
      .then((info) => {
        setProjectInfo(info);
        if (info.deployments && info.deployments.length > 0) {
          setDeployment(info.deployments[0]);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  }, [user]);

  if (!projectInfo) {
    return <ProjectDetailContainer>loading</ProjectDetailContainer>;
  }

  return (
    <ProjectDetailContainer>
      <ProjectDetailHeaderDiv>
        <div style={{ flexBasis: `295px` }} className={"infoBox"}>
          <span className={"head"}>{projectInfo.name}</span>
          {projectInfo.location?.nearest_town}
        </div>
        <div style={{ paddingRight: `279px` }} className={"infoBox"}>
          <DeploymentSelector onClick={() => setShowSelect(true)}>
            <DeploymentDetail deployment={deployment} />
            {showSelect && (
              <div className={"choices"}>
                {projectInfo.deployments.map((d) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeployment(d);
                      setShowSelect(false);
                    }}
                    key={d.id}
                    className={"choice"}
                  >
                    <DeploymentDetail deployment={d} />
                  </div>
                ))}
              </div>
            )}
            <ArrowIcon className={"arrow"} />
          </DeploymentSelector>
        </div>
      </ProjectDetailHeaderDiv>
      <ProjectTabsContainer>
        <PillButton
          onClick={() => document.getElementById("info")?.scrollIntoView()}
        >
          Overview
        </PillButton>
        <PillButton
          onClick={() => document.getElementById("charts")?.scrollIntoView()}
        >
          Chart Analysis
        </PillButton>
        <PillButton
          onClick={() => document.getElementById("streams")?.scrollIntoView()}
        >
          Streams
        </PillButton>
      </ProjectTabsContainer>
      <FocusDivRow id={"info"}>
        <ProjectMap
          project={projectInfo}
          center={{
            lat: deployment?.latitude || 0,
            lng: deployment?.longitude || 0,
          }}
        />
        <FeaturedHighlight
          selectedDeploymentId={deployment?.id}
          project={projectInfo}
        />
      </FocusDivRow>
      <Metrics selectedDeploymentId={deployment?.id} project={projectInfo} />
      <VideoArchive
        selectedDeploymentId={deployment?.id}
        project={projectInfo}
      />
    </ProjectDetailContainer>
  );
};

export const StatusIcon = (props: {
  updatedDate: DateTime;
  updating: boolean;
}) => {
  let statusText =
    (props.updating ? `Currently running ... ` : ``) +
    `Last updated ${props.updatedDate.toRelative()} ` +
    `@ ${props.updatedDate.toFormat("LLL")}`;

  return (
    <HtmlTooltip placement={"top-end"} title={statusText}>
      <DivSubtitle>
        {props.updating ? (
          <SyncRoundedIcon />
        ) : (
          <CheckCircleOutlineRoundedIcon />
        )}
      </DivSubtitle>
    </HtmlTooltip>
  );
};
