import * as React from "react";
import styled from "styled-components";
import "/public/styles/color.css";
import "/public/styles/size.css";
import { StyledNavRow } from "../../styles";
import { LoggedIn } from "../LogIn";

export const SentinelNavHeader = () => {
  return (
    <StyledNavRow>
      <SentinelNavBlock>
        <SentinelLogo />
      </SentinelNavBlock>
      <SentinelNavBlock>
        <LoggedIn />
      </SentinelNavBlock>
    </StyledNavRow>
  );
};

const SentinelNavBlock = styled.div`
  color: var(--colorContent-muted);
`;

const SentinelLogo = () => {
  return (
    <SentinelLogoBlock>
      <LogoSvg width="40" height="46" version="2.0">
        <use href="#sentinel-logo" />
      </LogoSvg>
      <LogoSvg width="123" height="22" version="2.0">
        <use href="#sentinel-text" />
      </LogoSvg>
    </SentinelLogoBlock>
  );
};

const SentinelLogoBlock = styled.div`
  color: #ff6f61;
`;

const LogoSvg = styled.svg`
  vertical-align: middle;
  padding: 5px;
`;

export const SentinelContainer = styled.div`
  background-color: var(--colorBackground-default);
`;
