import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Theme } from "../../styles";
import { PureComponent } from "react";
import styled from "styled-components";

export const BarOrLineFromMetricsSeries = (props: {
  m: MetricsTimeSeries;
  interval: Date[];
}) => {
  console.log(props.interval);
  console.log(props.m);
  if (props.m.value_map.length < 1) {
    return <h6>not enough data</h6>;
  } else if (props.m.value_map.length == 1) {
    // then we assume we want to see a bar chart
    var dataBars: { label: string; value: number }[] = [];
    for (let i in props.interval) {
      dataBars.push({
        label: props.interval[i].toLocaleDateString(),
        value: props.m.value_map[0].value_list[i],
      });
    }

    return (
      <ChartDiv style={{ marginTop: `auto` }}>
        <ResponsiveContainer width="100%" minHeight={`100px`}>
          <BarChart
            maxBarSize={23}
            barGap={1}
            data={dataBars}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          >
            <Bar dataKey="value" fill={Theme.colors.blue} />
            <CartesianGrid vertical={false} />

            <XAxis dataKey={"label"} stroke={Theme.colors.white} />
            <YAxis stroke={Theme.colors.white} />
          </BarChart>
        </ResponsiveContainer>
      </ChartDiv>
    );
  } else {
    // then we assume a line chart is the way to go
    let dataLines: { [key: string]: string | number }[] = [];
    let taxonIDs: string[] = [];
    for (let i in props.interval) {
      let newVal: { [key: string]: string | number } = {
        label: props.interval.toLocaleString(),
      };
      for (let j in props.m.value_map) {
        if (taxonIDs.indexOf(String(props.m.value_map[j].taxon_id)) == -1) {
          taxonIDs.push(String(props.m.value_map[j].taxon_id));
        }
        newVal[String(props.m.value_map[j].taxon_id)] =
          props.m.value_map[j].value_list[i];
      }
      dataLines.push(newVal);
    }

    return (
      <ChartDiv style={{ marginTop: `auto` }}>
        <ResponsiveContainer width="100%" minHeight={`100px`}>
          <LineChart data={dataLines}>
            {taxonIDs.map((id) => (
              <Line
                dataKey={id}
                dot={false}
                stroke={Theme.colors.purple}
                strokeWidth={2}
              />
            ))}
            {/*<Tooltip />*/}
            <CartesianGrid vertical={false} />
            <XAxis dataKey={"label"} stroke={Theme.colors.white} />
            <YAxis stroke={Theme.colors.white} />
          </LineChart>
        </ResponsiveContainer>
      </ChartDiv>
    );
  }
};

export const LineFromSeries = (props: {
  m: { v: number | undefined; v2: number | undefined; label: string }[];
  lines: {
    dataKey: string;
    label: string;
    color: string;
  }[];
}) => {
  // const Label = (props: any) => {
  //     const {cx, cy, stroke, value} = props
  //     return (
  //         <text cx={cx} cy={cy} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
  //             {value}
  //         </text>
  //     )
  // }
  const lineComponents = [];
  for (let i = 0; i < props.lines.length; i++) {
    lineComponents.push(
      <Line
        key={i}
        dataKey={props.lines[i].dataKey}
        name={props.lines[i].label}
        isAnimationActive={false}
        strokeWidth={3}
        stroke={props.lines[i].color}
        dot={false}
      />,
    );
  }

  return (
    <ResponsiveContainer width="90%" height={`80%`}>
      <LineChart
        maxBarSize={23}
        barGap={1}
        data={props.m}
        margin={{ top: 0, bottom: 0, left: 0, right: 24 }}
      >
        <CartesianGrid stroke={Theme.colors.darkGrey} vertical={false} />
        <Tooltip
          label
          contentStyle={{ backgroundColor: Theme.colors.black }}
          formatter={(value) => (value % 1 != 0 ? value.toFixed(2) : value)}
        />

        <XAxis
          fontSize={`12px`}
          tickSize={0}
          tickMargin={13}
          interval={"preserveStartEnd"}
          minTickGap={20}
          axisLine={false}
          dataKey={"label"}
          stroke={Theme.colors.white}
        />
        <YAxis
          fontSize={`12px`}
          tickSize={0}
          tickMargin={0}
          axisLine={false}
          tickCount={4}
          stroke={Theme.colors.white}
        />
        {lineComponents}
      </LineChart>
    </ResponsiveContainer>
  );
};
// label={{fill: Theme.colors.orange, dy: -15}}
// export const LinesFromMetricsSeries = (props: {m: MetricsTimePoint[], metricType: string}) => {
//     if (props.m.length < 1) {
//         return (
//             <h6>not enough data</h6>
//         )
//     }
//     var data: {label: string; value: number;}[] = [];
//     switch (props.metricType) {
//         case "Functional Diversity":
//             for (let i in props.m) {
//                 data.push({label: props.m[i].time.toLocaleDateString(), value: props.m[i].functional_diversity});
//             }
//             break;
//         case "Functional Richness":
//             for (let i in props.m) {
//                 data.push({label: props.m[i].time.toLocaleDateString(), value: props.m[i].functional_richness});
//             }
//             break;
//         case "Functional Evenness":
//             for (let i in props.m) {
//                 data.push({label: props.m[i].time.toLocaleDateString(), value: props.m[i].functional_evenness});
//             }
//             break;
//         // case "Mean Count":
//         //     for (let i in props.m) {
//         //         data.push({label: props.m[i].time.toLocaleDateString(), value: props.m[i].mean_count});
//         //     }
//         //     break;
//         // case "Max N":
//         //     for (let i in props.m) {
//         //         data.push({label: props.m[i].time.toLocaleDateString(), value: props.m[i].max_n});
//         //     }
//         //     break;
//         case "Species Richness":
//             for (let i in props.m) {
//                 data.push({label: props.m[i].time.toLocaleDateString(), value: props.m[i].species_richness});
//             }
//             break;
//
//     }
//     // let data: {label: string; value: number; value2: number;}[] = [
//     //     {label: "Functional Diversity", value: props.m[0].functional_diversity, value2: props.m[1].functional_diversity},
//     //     {label: "Functional Evenness", value: props.m[0].functional_evenness, value2: props.m[1].functional_evenness},
//     //     {label: "Functional Richness", value: props.m[0].functional_richness, value2: props.m[1].functional_richness},
//     //     {label: "Mean Count", value: props.m[0].mean_count, value2: props.m[1].mean_count},
//     //     {label: "Max N", value: props.m[0].max_n, value2: props.m[1].max_n},
//     //     {label: "Species Richness", value: props.m[0].species_richness, value2: props.m[1].species_richness},
//     // ]
//
//     return (
//         <ChartDiv>
//             <ResponsiveContainer width="100%" height="100%">
//
//                 <BarChart width={100} height={40} data={data}>
//                     <Bar dataKey="value" fill={Theme.colors.purple} />
//                 </BarChart>
//
//             </ResponsiveContainer>
//         </ChartDiv>
//
//     )
//
//
// }

//
// {/*<RadarChart outerRadius={"80%"} data={data}>*/}
// {/*    <PolarGrid stroke={Theme.colors.white} />*/}
// {/*    <PolarAngleAxis domain={[0,100]} tick={{fill: Theme.colors.white, fontFamily: Theme.fonts.heading, fontWeight: 800, width: `40px`}} dataKey="label" />*/}
// {/*    <PolarRadiusAxis domain={[0,100]} angle={90} stroke={Theme.colors.white} />*/}
// {/*    <Radar name="Current" dataKey="value" stroke={Theme.colors.blue} strokeWidth={3} fill={"transparent"} fillOpacity={0.6} />*/}
// {/*    <Radar name="Last Month" dataKey="value2" stroke={Theme.colors.purple} strokeWidth={3} fill={"transparent"} fillOpacity={0.6} />*/}
// {/*    <Legend />*/}
// {/*</RadarChart>*/}

const ChartDiv = styled.div`
  display: flex;
  overflow: hidden;
`;

interface MetricsTimeSeries {
  // type: "relative" | "absolute"; // ACTUALLY THEY"RE ALL ABSOLUTE, our metrics make no sense in the relative context
  // interval: Date[];
  value_map: {
    taxon_id: number;
    value_list: number[];
  }[]; // first number is taxon id, second is value list
  metric:
    | "functional_diversity"
    | "functional_richness"
    | "functional_evenness"
    | "species_richness"
    | "mean_count"
    | "max_number";
}
