import * as React from "react";
import { DirectoryContainer, DirectoryListing } from "./styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Business, PlayArrow } from "@mui/icons-material";
import { Theme } from "../../styles";
import { ProjectInfo } from "../../types";
import { useEffect, useState } from "react";
import { FetchAllProjectInfo } from "../../api/general";
import { toast } from "react-toastify";
import { auth } from "../../firebase";
import { useIdToken } from "react-firebase-hooks/auth";
//
// // first method
// import Alarm from '@material-ui/icons/Alarm';

// // second method
// import { Pin } from '@mui/icons-material';

export const ProjectDirectory = () => {
  const [projectsInfo, setProjectsInfo] = useState<ProjectInfo[]>([]);
  const [user, loading, error] = useIdToken(auth);

  useEffect(() => {
    if (!user) {
      return;
    }
    FetchAllProjectInfo(user)
      .then((infos) => {
        setProjectsInfo(infos);
      })
      .catch((e) => {
        toast.error(e);
      });
  }, [user]);

  return (
    <>
      <h2>our projects</h2>
      <DirectoryContainer>
        {projectsInfo.map((p) => (
          <DirectoryListing
            key={p.id}
            onClick={() => window.location.assign("/project/" + p.id)}
          >
            <img src={"/public/project_" + p.id + ".png"} />
            <h4>{p.name}</h4>
            <br />
            <LocationOnIcon fontSize={`inherit`} />{" "}
            <span style={{ color: Theme.colors.blue }}>Location:</span>{" "}
            {p.location?.label}
            <br />
            {/*<PlayArrow fontSize={`inherit`} /> <span style={{color: Theme.colors.blue}}>Start date:</span> {p.start_date.toLocaleDateString()}<br />*/}
            <Business fontSize={`inherit`} />{" "}
            <span style={{ color: Theme.colors.blue }}>Project Developer:</span>{" "}
            {p.developer?.name}
          </DirectoryListing>
        ))}
      </DirectoryContainer>
    </>
  );
};
