import styled, { createGlobalStyle } from "styled-components";
import * as React from "react";
const logo = "/public/streamocean_Logo_Gradient.png";
const textLogo = "/public/logoTypeWhite.png";
const iconLogo = "/public/logoWhite.png";

export const Theme = {
  colors: {
    blue: `#5DC1CC`,
    purple: `#BF00FF`,
    black: `#000000`,
    darkGrey: `#404040`,
    white: `#FFFFFF`,
    pink: `#FFA9A0`,
    darkPink: `#FF6F61`,
    grey: `#CED2DB`,
    brightPink: `#EE249D`,
    green: `#00CC99`,
    yellow: `#E6D800`,
    lightBlue: `#45D2F4`,
    orange: `#FFAE42`,
  },
  fonts: {
    body: `Stream, sans-serif`,
    subtitle: `Roboto Mono, monospace`,
    heading: `Numans, serif`,
  },
};

export const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: "Stream";
      src: url("/public/fonts/Stream_DemiBold.otf");
      font-style: normal;
      font-weight: 700;
      font-display: swap;
  }
  @font-face {
      font-family: "Stream";
      src: url("/public/fonts/Stream_Light.otf");
      font-style: normal;
      font-weight: 100;
      font-display: swap;
  }
    @font-face {
      font-family: "Stream";
      src: url("/public/fonts/Stream_Medium.otf");
      font-style: normal;
      font-weight: 600;
      font-display: swap;
  }
  @font-face {
      font-family: "Stream";
      src: url("/public/fonts/Stream_Regular.otf");
      font-style: normal;
      font-weight: 400;
      font-display: swap;
  }
`;

export const CenteredPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  min-height: 100vh;
  background-color: ${Theme.colors.black};
  h1 {
    text-transform: lowercase;
    color: ${Theme.colors.white};
    font-family: ${Theme.fonts.heading};
    font-weight: 400;
    font-size: 34px;
    margin: 0;
  }
  h2 {
    text-transform: uppercase;
    color: ${Theme.colors.white};
    font-family: ${Theme.fonts.subtitle};
    font-weight: 600;
    font-size: 26px;
    margin: 30px;
  }
  h3 {
    text-transform: uppercase;
    color: ${Theme.colors.blue};
    font-family: ${Theme.fonts.subtitle};
    font-weight: 400;
    font-size: 20px;
    margin: 30px;
  }
  h4 {
    color: ${Theme.colors.white};
    font-family: ${Theme.fonts.subtitle};
    font-weight: 400;
    font-size: 14pt;
    padding: 0;
    margin: 0;
  }
  h5 {
    color: ${Theme.colors.white};
    font-family: ${Theme.fonts.subtitle};
    font-size: 16px;
    margin: 5px 0;
    padding: 0;
  }
  h6 {
    color: ${Theme.colors.blue};
    font-family: ${Theme.fonts.subtitle};
    font-size: 14px;
    margin: 5px 0;
  }
  sub {
    font-family: ${Theme.fonts.subtitle};
    font-size: 14px;
    font-weight: 200;
  }
  color: ${Theme.colors.white};
  font-family: ${Theme.fonts.body};
  font-size: 10pt;
  a:link {
    color: ${Theme.colors.white};
    padding: 0;
  }
  a:link:hover {
    color: ${Theme.colors.blue};
  }
  a:link:active {
    color: ${Theme.colors.white};
  }
  a:link:visited {
    color: ${Theme.colors.white};
  }
  a:visited {
    color: ${Theme.colors.white};
  }
  a:visited:hover {
    color: ${Theme.colors.pink};
  }
  a:visited:active {
    color: ${Theme.colors.white};
  }
`;

export const MainPage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100vw;
  justify-content: center;
`;

export const SmallButton = styled.button`
  background-color: ${Theme.colors.black};
  height: 30px;
  width: 30px;
  display: block;
  margin: auto;
  text-transform: lowercase;
  color: ${Theme.colors.white};
  font-family: ${Theme.fonts.heading};
  font-size: 12pt;
  padding: 0;

  border-radius: 8px;
  border: 2px solid ${Theme.colors.black};
  :hover {
    background-color: ${Theme.colors.white};
    color: ${Theme.colors.black};
    h5 {
      color: ${Theme.colors.black};
    }
  }
`;

export const Blur = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: ${Theme.colors.white};
  opacity: 0.7;
  filter: blur(5px);
  z-index: 3;
`;

export const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: fixed;
  top: 50%;
  left: 50%;
  flex-grow: 1;
  border-radius: 20px;
  max-height: 90vh;
  overflow-y: auto;
  h2 {
    color: ${Theme.colors.black} !important;
  }
  h4 {
    margin: 10px 0;
    color: ${Theme.colors.black} !important;
  }
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-family: ${Theme.fonts.body};
  color: ${Theme.colors.black};
  background-color: ${Theme.colors.white};
  border: 3px solid ${Theme.colors.black};
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    input {
      height: 30px;
      margin: 5px 0;
      background-color: transparent;
      color: ${Theme.colors.black};
      border: none;
      font-family: ${Theme.fonts.heading};
      border-bottom: 2px solid ${Theme.colors.blue};
    }
    label {
      font-weight: bold;
    }
    select {
      height: 30px;
      margin: 5px 0;
      background-color: transparent;
      color: ${Theme.colors.black};
      border: none;
      font-family: ${Theme.fonts.heading};
      border-bottom: 2px solid ${Theme.colors.blue};
    }
    select:focus {
      outline: none !important;
      border-bottom: 2px solid ${Theme.colors.pink};
    }
    input:focus {
      outline: none !important;
      border-bottom: 2px solid ${Theme.colors.pink};
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 34px;
      height: 21px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${Theme.colors.pink};
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: ${Theme.colors.blue};
    }

    input:focus + .slider {
      box-shadow: 0 0 1px ${Theme.colors.pink};
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 21px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    button {
      align-self: center;
      width: 100px;
      cursor: pointer;
      height: 24px;
      background-color: ${Theme.colors.black};
      color: ${Theme.colors.white};
      font-size: 8pt;
      margin: 10px;
      text-transform: uppercase;
      border-radius: 12px;
      border: 2px solid ${Theme.colors.black};
      font-family: ${Theme.fonts.heading};
      font-weight: bold;
      :hover {
        color: ${Theme.colors.black};
        background-color: ${Theme.colors.white};
      }
    }
  }

  @media only screen and (max-width: 600px) {
    max-width: 90vw;
    max-height: 90vh;
  }
`;

// nav has 3 main groups:
// nav buttons left
// logo center
// user buttons right
export const StyledNavRow = styled.div`
  height: 120px;
  flex-shrink: 0;
  padding: 0 80px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div.navGroup {
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-grow: 0;
  }
  button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: ${Theme.colors.white};
    font-family: ${Theme.fonts.body};
    :hover {
      color: ${Theme.colors.pink};
    }
  }
`;

export const PillButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 16px;
  gap: 10px;
  font-family: ${Theme.fonts.subtitle};
  height: 32px;
  font-weight: 700;
  font-size: 12px;
  background-color: transparent;
  color: ${Theme.colors.white};
  cursor: pointer;
  border: 1px solid ${Theme.colors.white};
  border-radius: 16px;

  flex-grow: 0;
  :hover {
    background-color: ${Theme.colors.white};
    color: ${Theme.colors.black};
  }
`;

export const PillButtonDense = styled.button<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.selected ? `5px 10px` : `8px 10px`)};
  align-self: center;
  gap: 10px;
  font-family: ${Theme.fonts.subtitle};
  font-weight: ${(props) => (props.selected ? `700` : `400`)};
  font-size: 16px;
  background-color: ${(props) =>
    props.selected ? Theme.colors.pink : Theme.colors.white};
  color: ${(props) =>
    props.selected ? Theme.colors.white : Theme.colors.black};

  border: none;
  border-radius: 4px;

  flex-grow: 0;
  transition: padding 0.25s;
`;

export const AgileButton = styled.button`
  background-color: ${Theme.colors.white};
  height: 24px;
  width: 100px;
  display: block;
  margin: 10px;
  border-radius: 12px;
  border: 5px solid ${Theme.colors.white};

  text-transform: uppercase;
  color: ${Theme.colors.black};
  font-family: ${Theme.fonts.heading};
  font-size: 7pt;
  padding: 0 5px;
  :hover {
    font-weight: 150;
    border: 5px solid ${Theme.colors.pink};
  }
`;

export const MainContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Foot = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
  margin-top: auto;
  border-top: 1px solid ${Theme.colors.white};
  align-items: center;
  justify-content: flex-end;
  a.textLink {
    margin: 20px;
  }
`;

export const FlexCol = styled.div`
  display: flex;
  position: relative;
  padding: 0px;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  p {
    max-width: 400px;
  }
`;

export const BackArrow = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  top: 0;
  left: 0;
  :hover {
    cursor: pointer;
    h2 {
      color: ${Theme.colors.blue};
    }
  }
`;
export const FullLogo = styled.div`
  background-image: url(${logo});
  height: 3rem;
  width: 200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const IconButton = styled.button`
    border: none;
    width: 40px;
    border-radius: 20px;
    margin: 5px;
    background-color: ${Theme.colors.white};
    height: 40px;
    color: ${Theme.colors.black};
    font-size 12pt;
    :hover {
        color: ${Theme.colors.blue};
    }
`;
