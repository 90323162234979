import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FetchLatestMedia,
  FetchMediaMetrics,
  FetchProjectInfo,
} from "../../../api/general";
import { capitalizeInitial } from "../../../utils/text";
import { DateTime } from "luxon";
import styled from "styled-components";
import "/public/styles/color.css";
import "/public/styles/size.css";
import { auth } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  MetricStatus,
  TaggedMetric,
  meanCountMetric,
  maxCountMetric,
  speciesEvennessMetric,
  speciesDiversityMetric,
  speciesIdentifiedMetric,
  speciesRichnessMetric,
} from "../../../domain/metrics";

export const MediaDetailPage = (props: {}) => {
  const [theme, setTheme] = useState<string>("Light");
  const [metrics, setMetrics] = useState(null);
  const [taxonomy, setTaxonomy] = useState({
    1: {
      common_name: "animal",
      scientific_name: "animalia",
    },
    2: {
      common_name: "Indo-Pacific sergeant",
      scientific_name: "abudefduf vaigiensis",
    },
  });
  const [media, setMedia] = useState({
    id: "10_1712809802.mp4",
    project_id: 5,
    time: "2024-04-11T09:30:02+05:00",
    url: "https://storage.googleapis.com/so-media-intake/10_1712809802.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256\u0026X-Goog-Credential=so-server-dev%40streamocean.iam.gserviceaccount.com%2F20240412%2Fauto%2Fstorage%2Fgoog4_request\u0026X-Goog-Date=20240412T122936Z\u0026X-Goog-Expires=3599\u0026X-Goog-Signature=6d4098122413987a5bd90f0474c96403fae831d5964dc648cd02bb6c321a75eb718de5617e2ccacc4d9bf7283a6051a80f70eaa8619c8acfd4a3ada55c1c064e3a0d410a805f5efae869d075f12b34d07355113de495768e0d9a0a7ef9e92992a4623d3a364cc600c41e7d2d8c3afa2499ae342ec5446f8591f8d1b06e015c0fd8ca7837d407b6bfd66498aeef932b997feedd23edb7aa736db9fc294fcd25832557d4f1656e25a13f68268feba959c8239bca0c5eaec10b7cf255d11ff15461ab6402bd30f0a4b2642d5a9a6c40cacb75d03b55b2298dab5f63cf8b6cbf61753cc93bb4eb2822988c0565b8a9b846db179e5c084a021a24a25020e8a26e7004\u0026X-Goog-SignedHeaders=host\u0026response-content-disposition=download",
    size: 135236237,
  });
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);
  const [user, loading, error] = useAuthState(auth);
  let id: string;
  ({ id } = useParams());
  useEffect(() => {
    if (!user) {
      return;
    }
    FetchMediaMetrics(user, id).then((m) => {
      setMetrics(m);
    });
    FetchProjectInfo(user, media.project_id).then((info) => {
      setProjectInfo(info);
    });
  }, [user, id]);
  return (
    <>
      <MediaMetadata project={projectInfo} media={media} />
      <MetricsDisplay
        metrics={metrics}
        media={media}
        taxonomy={taxonomy}
        now={DateTime.now()}
      />
    </>
  );
};

const MediaMetadata = ({ project, media }) => {
  if (media == null) {
    return null;
  }
  return (
    <MediaMetadataContainer>
      <MediaAttribution>@dlunsford / taiwan</MediaAttribution>
      <MediaTitleBar>
        <MediaTitle>{media.id}</MediaTitle>
      </MediaTitleBar>
      <MediaMetadataDetail>
        <MediaMetadataBlock>
          <MediaMetadataItem
            label="Managed by"
            value={project?.developer?.name}
          />
          <MediaMetadataItem label="Video Duration" value="22hr 4m 5s" />
          <MediaMetadataItem
            label="Survey Date"
            value={media.time.split("T")[0]}
          />
          <MediaMetadataItem
            label="Survey Time"
            value={media.time.split("T")[1]}
          />
          <MediaMetadataItem
            label="Location"
            value={project?.location?.nearest_town}
          />
        </MediaMetadataBlock>
      </MediaMetadataDetail>
    </MediaMetadataContainer>
  );
};

const MediaMetadataBlock = styled.div`
  display: inline-block;
  /*position: absolute;*/
  text-align: centre;
`;

const MediaMetadataItem = ({ label, value }) => {
  return (
    <MediaMetadataItemContainer>
      <div>{label}</div>
      <MediaMetadataLosenge>{value}</MediaMetadataLosenge>
    </MediaMetadataItemContainer>
  );
};

const MediaMetadataItemContainer = styled.div`
  display: inline-block;
  padding: var(--space-100);
`;

const MediaMetadataContainer = styled.div`
  color: var(--colorContent-default);
`;

const MediaAttribution = styled.div``;

const MediaTitleBar = styled.div``;

const MediaTitle = styled.h3``;

const MediaMetadataDetail = styled.div`
  vertical-align: middle;
`;

const MediaMetadataLosenge = styled.div`
  padding: var(--space-025) var(--space-100);
  border-radius: var(--borderRadius-full);
  background-color: var(--colorBackground-neutral-muted);
  display: inline-block;
`;

const MetricsDisplay = ({ media, metrics, taxonomy, now }) => {
  return (
    <VerticalGrid>
      <HorizontalGrid>
        <VideoDisplay media={media} />
        <VerticalGrid>
          <ScalarMetric
            label="Mean Count"
            description="Mean count per frame"
            metric={meanCountMetric(metrics)}
            now={now}
          />
          <ScalarMetric
            label="Max Count"
            description="Max count per frame"
            metric={maxCountMetric(metrics)}
            now={now}
          />
        </VerticalGrid>
      </HorizontalGrid>
      <HorizontalGrid>
        <ArrayMetric
          label="Species identified"
          metric={speciesIdentifiedMetric(metrics, taxonomy)}
          now={now}
        />
        <ScalarMetric
          label="Species Evenness"
          description="Species evenness over entire video"
          metric={speciesEvennessMetric(metrics)}
          now={now}
        />
        <ScalarMetric
          label="Species Diversity"
          description="Species diversity over entire video"
          metric={speciesDiversityMetric(metrics)}
          now={now}
        />
        <ScalarMetric
          label="Species Richness"
          description="Species richness over entire video"
          metric={speciesRichnessMetric(metrics)}
          now={now}
        />
      </HorizontalGrid>
    </VerticalGrid>
  );
};

// TODO: Move VideoWithContols somewhere shared.
const VideoWithControls = (props: { src: string }) => {
  const vid = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
  }, [props.src]);

  return (
    <>
      <video ref={vid} src={props.src} controls />
    </>
  );
};

// TODO: Grab most recent video from bucket for now.
const VideoDisplay = ({ media }) => {
  return (
    <VideoContainer>
      <VideoWithControls src={media.url} />
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  display: flex;
  width: 905px;
  align-items: center;
  justify-content: center;
  border: solid;
  border-radius: var(--border-radius-xlarge);
  border-color: var(--colorBorder-default);
  border-width: 1px;
  color: var(--colorBorder-default);
  video {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

// metric:
//   icon
//   name
//   description
//   value
//   age

const HorizontalGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--space-300);
`;

const VerticalGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-300);
`;

const ScalarMetric = ({
  label,
  description,
  metric,
  now,
}: {
  label: string;
  description: string;
  metric: TaggedMetric;
  now: DateTime;
}) => {
  return (
    <MetricBox>
      <MetricHeader>
        <MetricLabel>
          <MetricIcon href="#icon-fish" />
          {label}
        </MetricLabel>
      </MetricHeader>
      <MetricResultOuter>
        <MetricResultWatermark />
        <MetricResultInner>
          <MetricDescription>{description}</MetricDescription>
          <MetricValue>{metric?.value || "No data"}</MetricValue>
        </MetricResultInner>
      </MetricResultOuter>
      <MetricFooter>
        <StatusBadge
          status={metric?.status || MetricStatus.Offline}
          updated={metric?.latestTime || DateTime.now()}
          now={now}
        />
      </MetricFooter>
    </MetricBox>
  );
};

const ArrayMetric = ({
  label,
  metric,
  now,
}: {
  label: string;
  metric: TaggedMetric<Array>;
  now: DateTime;
}) => {
  const rows = [];
  for (let item of metric ? metric.value : []) {
    rows.push(<div key={item}>{capitalizeInitial(item)}</div>);
  }
  return (
    <MetricBox>
      <MetricHeader>
        <MetricLabel>
          <MetricIcon href="#icon-fish" />
          {label}
        </MetricLabel>
      </MetricHeader>
      <MetricResultOuter>
        <MetricResultWatermark />
        <MetricResultInner>
          <MetricArrayValue>
            {rows.length > 0 ? rows : "No data"}
          </MetricArrayValue>
        </MetricResultInner>
      </MetricResultOuter>
      <MetricFooter>
        <StatusBadge
          status={metric?.status || MetricStatus.Offline}
          updated={metric?.latestTime || DateTime.now()}
          now={now}
        />
      </MetricFooter>
    </MetricBox>
  );
};

const MetricHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter: -1%;
  weight: 600;
  color: var(--colorContent-default);
`;

const MetricResultOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*width: 100%;*/
  height: 100%;
  gap: 8px;
`;

const MetricResultInner = styled.div`
  padding-right: var(--space-100);
  padding-left: var(--space-100);
  gap: var(--space-100);
  z-index: 1;
`;

const MetricFooter = styled.div`
  gap: 10px;
  text-align: right;
`;

const MetricBox = styled.div`
  flex-direction: column;
  display: flex;
  width: 320px;
  height: 264px;
  padding-top: var(--space-100);
  padding-right: var(--space-150);
  padding-bottom: var(--space-100);
  padding-left: var(--space-150);
  border: solid;
  border-width: 1px;
  border-color: var(--colorBorder-default);
  border-radius: var(--border-radius-large);
`;

const MetricLabel = styled.div``;

const MetricDescription = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter: -1%;
  color: var(--colorContent-neutral);
`;
const MetricValue = styled.div`
  width: 100%;
  text-align: center;
  font-size: 64px;
  line-height: 80px;
  letter: -2%;
  color: var(--colorContent-accent);
`;
const MetricArrayValue = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: var(--colorContent-accent);
`;

const MetricResultWatermark = () => {
  return (
    <svg
      style={{ position: "absolute", fillOpacity: 0.22 }}
      width="144"
      height="144"
      version="2.0"
    >
      <use href="#logo-brandmark" />
    </svg>
  );
};

const StatusBadge = ({
  status,
  updated,
  now,
}: {
  status: MetricStatus;
  updated: DateTime;
  now: DateTime;
}) => {
  switch (status) {
    case MetricStatus.UpToDate:
      return <UpToDateBadge updated={updated} now={now} />;
    case MetricStatus.Outdated:
      return <OutdatedBadge updated={updated} now={now} />;
    case MetricStatus.Running:
      return <RunningBadge updated={updated} now={now} />;
    case MetricStatus.Offline:
      return <OfflineBadge updated={updated} now={now} />;
    case MetricStatus.New:
      return <NewBadge updated={updated} now={now} />;
    default:
      return <></>;
  }
};

const UpToDateBadge = ({
  updated,
  now,
}: {
  updated: DateTime;
  now: DateTime;
}) => {
  return (
    <StatusBlock className="status-up-to-date">
      <Age then={updated} now={now} />
      <BadgeIcon href="#patch-check" />
    </StatusBlock>
  );
};

const OutdatedBadge = ({
  updated,
  now,
}: {
  updated: DateTime;
  now: DateTime;
}) => {
  return (
    <StatusBlock className="status-outdated">
      <Age then={updated} now={now} />
      <BadgeIcon href="#exclamation-triangle" />
    </StatusBlock>
  );
};

const RunningBadge = ({
  updated,
  now,
}: {
  updated: DateTime;
  now: DateTime;
}) => {
  return (
    <StatusBlock className="status-running">
      Running
      <BadgeIcon href="#soundwave" />
    </StatusBlock>
  );
};

const OfflineBadge = ({
  updated,
  now,
}: {
  updated: DateTime;
  now: DateTime;
}) => {
  return (
    <StatusBlock className="status-offline">
      Offline
      <BadgeIcon href="#x-octagon" />
    </StatusBlock>
  );
};

const NewBadge = ({ updated, now }: { updated: DateTime; now: DateTime }) => {
  return (
    <StatusBlock className="status-new">
      New
      <BadgeIcon href="#info-circle" />
    </StatusBlock>
  );
};

const StatusBlock = styled.div`
  display: inline-block;
  padding-right: var(--space-075);
  padding-left: var(--space-075);
  border-radius: var(--border-radius-xsmall);
  gap: var(--space-050);
`;

export const Age = ({ then, now }: { then: DateTime; now: DateTime }) => {
  let { val, units } = approximateDuration(now.diff(then));
  return (
    <>
      {val}
      {shortTimeUnits(units)}
    </>
  );
};

const shortTimeUnits = (units: string): string => {
  switch (units) {
    case "seconds":
      return "sec";
    case "minutes":
      return "min";
    case "hours":
      return "h";
    case "days":
      return "d";
    case "weeks":
      return "w";
    case "months":
      return "mo";
    case "years":
      return "y";
    default:
      return units;
  }
};

const approximateDuration = (
  duration: Duration,
): { val: number; units: string } => {
  const units_ = [
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
  ];
  for (let units of units_) {
    if (duration.as(units) >= 1.0) {
      return { val: Math.trunc(duration.as(units)), units: units };
    }
  }
  return { val: Math.ceil(duration.as("seconds")), units: "seconds" };
};

const BadgeIcon = ({ href }: { href: string }) => {
  return (
    <svg width="12" height="12" version="2.0">
      <use href={href} />
    </svg>
  );
};

const MetricIcon = ({ href }: { href: string }) => {
  return (
    <svg width="16" height="10" version="2.0">
      <use href={href} />
    </svg>
  );
};
